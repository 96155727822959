import { Grid, MenuItem } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { inject, observer } from 'mobx-react';
import React from 'react';

import AppController from 'base/App.controller';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import DialogWrapper from 'components/common/wrappers/DialogWrapper';
import FormWrapper from 'components/common/wrappers/FormWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';

// constants
import { TOKEN, BNPP } from 'constants/resellers';
import { ISuperAdminModalProps } from '../types';
import { Itranslation } from 'types';

const SuperAdminModal: React.FC<ISuperAdminModalProps> = ({ AppStore, SuperAdminModalStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, ['users', 'common']);
    const {
        loading,
        form,
        handleSave,
        isFilled,
        handleChange,
        resellerMemberTypes,
        handleSelectAll,
        products,
        handleClear,
    } = SuperAdminModalStore;

    const ref = React.createRef();

    const handleSubmit = () => {
        return handleSave(AppStore);
    };

    const _handleClose = () => {
        handleClear();
        SuperAdminModalStore.closeModal();
    };

    return (
        <DialogWrapper
            open={SuperAdminModalStore.isOpen}
            onClose={_handleClose}
            className={'SuperAdminModal'}
            title={t('addReseller')}>
            <div className={'formContainer'}>
                <FormWrapper
                    className="form sectionParameters"
                    formRef={ref}
                    onSubmit={handleSubmit}
                    isValid={isFilled}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextFieldWrapper
                                name="resellerName"
                                type="text"
                                label={t('resellerName')}
                                value={form.resellerName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldWrapper
                                name="clientId"
                                type="text"
                                label={t('clientId')}
                                value={form.cliendId}
                                onChange={handleChange}
                                inputProps={{ maxLength: 10 }}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="firstName"
                                type="text"
                                label={t('firstName')}
                                value={form.firstName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="lastName"
                                type="text"
                                label={t('lastName')}
                                value={form.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="email"
                                type="email"
                                label={t('email')}
                                value={form.email}
                                disabled={SuperAdminModalStore.type === 'edit'}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectWrapper
                                name="products"
                                label={t('products')}
                                value={form.products}
                                onChange={handleChange}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected: unknown) => {
                                        if (Array.isArray(selected)) return selected.join(', ');
                                        return '';
                                    },
                                }}
                                hideOptional>
                                <MenuItem>
                                    <CustomThemeProvider alt>
                                        <Checkbox
                                            name={'products'}
                                            checked={form.selectAll.products}
                                            onChange={handleSelectAll}
                                        />
                                    </CustomThemeProvider>
                                    {t('all')}
                                </MenuItem>
                                {products.map((p) => (
                                    <MenuItem key={p} value={p}>
                                        <CustomThemeProvider alt>
                                            <Checkbox checked={form.products.indexOf(p) > -1} />
                                        </CustomThemeProvider>
                                        {p}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="aliasValue"
                                type="text"
                                onChange={handleChange}
                                label={t('aliasValue')}
                                value={form.aliasValue}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectWrapper
                                name="memberType"
                                label={t('memberType')}
                                value={form.memberType}
                                onChange={handleChange}
                                required>
                                {resellerMemberTypes.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {t(s)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectWrapper
                                name="scope"
                                label={t('scope')}
                                value={form.scope}
                                onChange={handleChange}
                                required>
                                {[TOKEN, BNPP].map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {t(s)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                    </Grid>
                </FormWrapper>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={_handleClose}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            className={'saveButton'}
                            loading={loading}
                            disabled={!isFilled}
                            onClick={handleSubmit}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore')(observer(SuperAdminModal));
